<template>
  <div class="admin">
    <b-row>
      <b-col cols="4">
        <p class="text-header">Admin</p>
      </b-col>
      <b-col cols="8">
        <div class="d-flex">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.name"
                placeholder="Admin"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="searchUserAdmin"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                  @click="searchUserAdmin"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <b-button class="btn-filter ml-2" @click="openSideBar">
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text">Filter</span>
            </span>
          </b-button>
          <b-button class="btn-filter ml-2" @click="createAdmin">
            <span class="d-none d-md-block">
              <span>Create</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <SideBarFilter
      :filter="filter"
      ref="sidebarAdmin"
      placeholder="Admin"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      :hideStatusFilter="false"
    >
      <template v-slot:filter-option>
        <!-- <div class="mb-3">
          <InputText
            v-model="filter.name"
            textFloat="Admin"
            placeholder="Admin"
            type="text"
            name="textAdmin"
            isRequired
          />
        </div> -->
        <div class="my-3">
          <b-form-group label="Admin role" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="filter.adminRoleId"
              id="checkbox-admin"
              :options="optionsRole"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </template>
    </SideBarFilter>

    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      :pageOptions="pageOptions"
      @filterPage="filterPage"
      @openModalAlertText="openModalAlertText"
    />

    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import Table from "./component/Table.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";

// import AutocompleteCustom from "@/components/inputs/AutocompleteCustom";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  components: {
    Table,
    ModalAlertText,
    // AutocompleteCustom,
  },
  data() {
    return {
      // state
      filter: {
        name: "",
        adminRoleId: [],
        skip: 1,
        take: 10,
        page: 1,
      },

      // state table
      items: [],
      fields: [
        {
          key: "displayName",
          label: "Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "adminRoleName",
          label: "Role",
          class: "w-100px text-nowrap",
        },
        {
          key: "enabled",
          label: "Status",
          class: "w-100px text-nowrap",
        },
        { key: "actions", label: "Action" },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state css
      iconSearch: false,

      // state option
      optionsRole: [],

      // state modal
      textModal: "",
      arrStatus: [],
    };
  },
  mounted() {
    this.searchUserAdmin();
    this.openModalAlertText();
    this.getOptionAdminRole();
  },
  methods: {
    async searchUserAdmin() {
      this.isBusy = true;

      const getData = await this.$services.admin.searchUserAdmin(this.filter);

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }

      this.isBusy = false;
    },
    async getOptionAdminRole() {
      console.log(this.getUserDetail);
      const getData = await this.$services.master.getOptionAdminRole(true);

      if (getData.result == 1) {
        this.optionsRole = getData.detail.map((x) => {
          return {
            text: x.name,
            value: x.id,
          };
        });
      }
    },
    openModalAlertText() {
      const getText = this.$store.getters.getTextModal;

      if (!getText) {
        return;
      }

      this.textModal = getText;

      this.$bvModal.show("modal-alert-text");
      this.$store.dispatch("UPDATE_TEXTMODAL", "");
    },
    searchAll(filter) {
      let { search } = filter;
      this.filter.name = search;
      this.filter.skip = 1;
      this.filter.page = 1;

      this.searchUserAdmin();
      this.closeSideBar();
    },
    clearFilter() {
      this.filter = {
        name: "",
        adminRoleId: [],
        skip: 1,
        take: 10,
        page: 1,
      };
      this.searchUserAdmin();
    },
    createAdmin() {
      this.$router.push("/admin/create");
    },
    openSideBar() {
      this.$refs.sidebarAdmin.show();
    },
    closeSideBar() {
      this.$refs.sidebarAdmin.hide();
    },
    filterPage(filter) {
      let { page } = filter;

      this.filter.skip = page;
      this.searchUserAdmin();
    },
  },
};
</script>

<style lang="scss" scoped>
.admin {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
}
.text-error {
  margin: 0;
  color: red;
}
// ! override
.g-form {
  width: 100%;
}

::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  min-width: 80px;
}
</style>
